@import 'styles/customBootstrap/bootstrap.scss';
@import 'styles/plugins/reactAutosuggest.scss';

@import 'react-toastify/dist/ReactToastify.css';

@import 'styles/_mixins';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

body {
  background-color: #ffffff;
  overflow-x: hidden;
  font-size: 14px;
}

.quick-search-header .react-autosuggest__input {
  width: 155px !important;
  height: 31px !important;
  padding: 0px 40px 0px 20px !important;
  border-radius: 100px !important;
}

.quick-search-header .Company-search-form .Btn-transparent {
  border-radius: 0 100px 100px 0 !important;
  padding: 0 !important;
  top: 1px !important;
  width: 40px !important;
  height: 29px !important;
}

.quick-search-header .react-autosuggest__container {
  margin-bottom: 0;
}

label {
  font-weight: 600;
  font-size: 14px;
}

.breadcrumb {
  background-color: transparent;
  padding-bottom: 1rem;
}

.breadcrumb-item {
  font-weight: 600;
  font-size: 14px;
  color: #8191ba;
}
.breadcrumb-item .active {
  font-weight: 600;
  font-size: 14px;
  color: #000000 !important;
}

//rating

.fa-star .rating_container {
  margin-right: 0.4rem;
}

.carousel-gallery {
  width: 100%;

  .carousel-control-prev {
    left: -70px;
    width: 10%;
    opacity: 1;
    padding-right: 30px;
  }

  .carousel-control-next {
    right: -70px;
    width: 10%;
    opacity: 1;
    padding-left: 30px;
  }
}

.modal-gallery {
  .sr-only {
    position: relative;
  }

  .close {
    color: #ffffff;
    opacity: 1;
    margin-bottom: 0;
    font-weight: 300;
    margin-right: 1px;
    text-shadow: none;
  }
}

.modal-header .close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.dropdown-menu.show {
  min-width: 50px;
}

#review-dropdown .dropdown-menu.show.dropdown-menu-right {
  inset: -14px auto auto -7px !important;
  margin-top: 15px;
}

.player-wrapper {
  .react-player__preview {
    border-radius: 4px;
  }

  .react-player__shadow {
    display: none !important;
  }
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

.nav-item {
  .dropdown-toggle.nav-link {
    padding: 0;
  }
}

.react-select__multi-value {
  background-color: #f3f4f6 !important;
  border-radius: 10px !important;
}

.react-select__multi-value__remove {
  color: $shade-70 !important;
  border-radius: 0 10px 10px 0 !important;

  &:hover {
    background-color: $shade-40 !important;
  }
}

.react-player__preview {
  border-radius: 4px;
}
.modal-body,
.modal-header,
.modal-footer {
  padding: 1.5rem !important;
}

.no-padding {
  padding: 0 !important; /* Overrides any padding set by default styles */
}

.recharts-wrapper > svg > g {
  outline: none !important;
  g {
    outline: none !important;
  }
}

// Customization for how tags are rendered in chat window

.cs-message__content {
  // it defaults to 'prewrap' which causes mystery spacing between tags
  white-space: inherit !important;
}

.cs-message__html-content {
  p {
    margin-block: 0;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
    @apply text-blue-500;
    &:hover {
      @apply text-blue-600;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

// DRIFT z-index style
#drift-frame-controller {
  z-index: 980 !important;
}

.cs-message.cs-message--incoming .cs-message__content {
  background-color: #eceff1;
  border-radius: 8px !important;
}

.cs-message.cs-message--outgoing .cs-message__content {
  background-color: #3b82f6;
  border-radius: 8px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
