@import 'styles/_mixins.scss';

.react-autosuggest__input {
  width: 100%;
  height: 42px;
  border: 1px solid $border;

  box-sizing: border-box;
  border-radius: 21px;
  padding: 9px 40px 9px 20px;

  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: $shade-100;

  &:hover,
  &:active {
    outline: none;
    box-shadow: 0 0 0 0.075rem rgba($color: $backgroundBlue, $alpha: 1), 0 0 0 0.2rem rgba($color: $shade, $alpha: 0.5);
  }
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  background-color: $shade;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
  z-index: 2;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $shade-10;
}

.react-autosuggest__suggestion:first-child {
  border-top: 1px solid $shade-10;
}

.react-autosuggest__suggestion--focused {
  background-color: $blue-80;
  color: $shade;
}

.react-autosuggest__suggestions-container {
  width: 100%;
}

.react-autosuggest__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 600;
  font-size: 15px;
  color: $shade-50;
  opacity: 1; /* Firefox */
}

.react-autosuggest__input:-ms-input-placeholder  {
  font-weight: 600;
  font-size: 15px;
  color: $shade-50;
}

.react-autosuggest__input::-ms-input-placeholder  {
  font-weight: 600;
  font-size: 15px;
  color: $shade-50;
}
