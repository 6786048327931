* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Descriptions

dl {
  margin-bottom: 0;
}

dt {
  font-weight: inherit;
}

dd {
  margin-bottom: 0;
}

// stop some browsers from displaying var tags in italic
var {
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  margin: 0;
}

a:hover,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--default);
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
  line-height: 1.4;
}

h6 {
  font-size: 12px;
  line-height: 1.4;
}

label {
  display: block;
  color: var(--default);
  font-weight: bold;
}

strong {
  font-weight: bold;
}

.px-white {
  color: var(--white);
}

.px-primary {
  color: var(--primary);
}

.px-danger {
  color: var(--danger);
}

.px-success {
  color: var(--success);
}

.px-gray {
  color: var(--gray);
}

.px-secondary-light {
  color: var(--secondary-light);
}

.px-semibold {
  font-weight: 600;
}

.px-bold {
  font-weight: bold;
}

.px-link {
  color: var(--secondary-light);
  cursor: pointer;
  font-weight: 600;
}

hr {
  border-top: 1px solid var(--background);
}

// TODO: replace vertical align classes with bootstrap classes

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.inline-separator {
  margin-right: 10px;
}

// Badges

.badge {
  padding: 6px 12px;
}

// Buttons

.btn {
  border-radius: 2px;
  text-shadow: none;
  font-size: 0.9rem;
  font-weight: 600;
}

.btn-primary {
  background-color: $blue;
  border-color: $blue;
  &:hover {
    background-color: $blue-dark;
    border-color: $blue-dark;
  }
}

.btn-success,
.btn-secondary,
.btn-info,
.btn-warning {
  color: var(--white);
}

.btn-warning {
  background-color: var(--warning);
}

.btn-warning:hover {
  color: var(--white);
}

.btn-neutral {
  background-color: var(--neutral);
  color: var(--default-light);
  border-color: var(--neutral);
}

.btn-neutral:hover {
  background-color: #ecedf3;
  color: var(--default-light);
  border-color: #ecedf3;
}

.btn-outline-success:hover {
  color: var(--white);
}

.btn:disabled {
  cursor: auto;
  pointer-events: none;
}

.btn-unstyled-secondary:focus,
.btn-unstyled-secondary.focus {
  box-shadow: none;
}

.px-btn-success--inverted {
  color: var(--success);
  background-color: var(--success-light);
  border: none;
}

.px-btn-primary--inverted {
  color: var(--primary);
  background-color: var(--primary-light);
  border: none;
}

.card {
  margin-bottom: 20px;
  padding: 20px;
  border: 0;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.1);
}

.card.clickable {
  transition: box-shadow 150ms ease-in;
  cursor: pointer;
  border-radius: 5px;
}

.card.clickable:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

input::placeholder,
.form-control::placeholder {
  color: var(--default-light);
}

.form-control,
input {
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  border-color: rgba(77, 121, 246, 0.5);
}

.form-control {
  height: calc(1.5em + 0.75rem + 5px);
  color: #2f5275;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--secondary-light);
  background-color: var(--secondary-light);
}

.btn:disabled,
.btn.disabled {
  // color: #ffffff;
}

.row.row--small-padding {
  margin-right: -10px;
  margin-left: -10px;
}

.row.row--small-padding > div {
  padding-right: 10px;
  padding-left: 10px;
}

.row.row--xs-padding {
  margin-right: -5px;
  margin-left: -5px;
}

.row.row--xs-padding > div {
  padding-right: 5px;
  padding-left: 5px;
}

.modal-header {
  align-items: flex-start;
}

.modal-header .svg-inline--fa {
  cursor: pointer;
}

.tooltip-inner {
  background-color: var(--black);
  color: var(--white);
}

.tooltip-groups {
  margin-right: 10px;
}

.px-cursor-text {
  cursor: text;
}

@media (max-width: 991px) {
  .tooltip.bs-tooltip-right {
    display: none;
  }
}

/****** Overrides from us ******/

/****** Container ******/

.container {
  max-width: 1264px;
  padding-left: 2rem;
  padding-right: 2rem;
}

/****** Buttons ******/

.btn {
  font-weight: 400;
  font-size: 14px;
}

.btn-lg {
  font-weight: 400;
  font-size: 16px;
}

.btn-unstyled {
  color: $shade-60;

  &:hover {
    color: $dark;
  }
}

/****** Nav / Navbar ******/

.navbar-dark {
  .navbar-nav {
    .nav-link {
      padding: 0 16px;
      color: #ffffff;
      &:focus,
      &:hover {
        color: #ffffff;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 0.625rem;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0 0.625rem;
  }
}

/* Needed to have the arrow for tooltip placement match the color background/theme of the popover ('ai', 'dark', or 'light') */
.popover-dark.bs-popover-top > .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: #434a54 !important;
}
.popover-dark.bs-popover-right > .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='right'] > .arrow::after {
  border-right-color: #434a54 !important;
}
.popover-dark.bs-popover-bottom > .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  border-bottom-color: #434a54 !important;
}
.popover-dark.bs-popover-left > .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='left'] > .arrow::after {
  border-left-color: #434a54 !important;
}

.popover-ai.bs-popover-top > .arrow::after,
.popover-ai.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: #3095b4 !important;
}
.popover-ai.bs-popover-right > .arrow::after,
.popover-ai.bs-popover-auto[x-placement^='right'] > .arrow::after {
  border-right-color: #3095b4 !important;
}
.popover-ai.bs-popover-bottom > .arrow::after,
.popover-ai.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  border-bottom-color: #3095b4 !important;
}
.popover-ai.bs-popover-left > .arrow::after,
.popover-ai.bs-popover-auto[x-placement^='left'] > .arrow::after {
  border-left-color: #3095b4 !important;
}
